import { tns } from 'tiny-slider';

var slider = tns({
  container: '.trends-slider',
  items: 1.4,
  slideBy: 'page',
  autoplay: false,
  touch: true,
  controls: false,
});
